import Vue from "vue";
import VueI18n from "vue-i18n";
import {DEFAULT_LANGUAGE} from '../constants/language'

Vue.use(VueI18n);

const messages = {
  ru: {
    delete: 'Удалить',
    cancel: 'Отмена',
    save: 'Сохранить',
    create: 'Создать',
    change: 'Изменить',
    close: 'Закрыть',
    reset: 'Сбросить',
    download: 'Скачать',
    upload: 'Загрузить',
    duplicate: 'Дублировать',
    update: 'Обновить',
    edit: 'Редактировать',
    exit: 'Выход',
    go: 'Перейти',
    search: 'Поиск',
    description: 'Описание',
    id: 'Id',
    field: 'Поле',
    name: 'Название',
    order: 'Порядок',
    value: 'Значение',
    langs: 'Языки',
    guides: 'Руководство',
    preview: 'Предпросмотр',
    endpointUrl: 'Эндпоинт',
    helloScreen: {
      welcome: 'Добро пожаловать!',
      joinInAdmin: 'Войти в админ-панель',
      welcomeBack: 'С возвращением',
      loginAndPass: 'Введите ваш логин и пароль, чтобы войти',
      login: 'Логин',
      password: 'Пароль',
      instance: 'Инстанс',
      signIn: 'Войти',
    },
    menu: {
      processes: 'Процессы',
      widgets: 'Виджеты',
      multiLang: 'Мультиязычность',
      settings: 'Настройки',
      templates: 'Шаблоны',
      'content-configurator': 'Конфигуратор контента',
      'variables-and-rules': 'Переменные и правила',
      variables: 'Переменные',
      rules: 'Правила',
      pageBuilder: 'Конструктор страниц',
      'external-sources': 'Внешние источники',
      integration: "Интеграции",
      references: "Справочники",
      'references-universal': "Универсальный справочник",
      'projects-and-processes': 'Проекты и процессы',
      'projects-list': 'Список проектов',
      endpoints: 'Эндпоинты',
      'page-builder': 'Редактирование узла',
      'properties': 'Cвойства',
      'properties-of-node': 'Свойства нод',
      'actions': 'Действия',
      'actions-list': 'Список действий',
    },
    settings: {
      basic: 'Основное',
      images: 'Изображения',
      colors: 'Цвета',
      projectName: 'Название проекта',
      plProject: 'Введите название проекта',
      companyName: 'Название компании',
      plCompany: 'Введите название компании',
      plSelectFile: 'Выберите файл',
      logo: 'Логотип',
      favicon: 'Фавикон',
      preloaderColor: 'Цвет прелоадер',
      timeResendCode: 'Время переотправки кода в секундах',
      plTimeResendCode: 'Введите время',
      globalUrl: 'Урла приложения',
      plGlobalUrl: 'Введите url',
      imageUrl: 'Путь к папке с изображениями',
      plImageUrl: 'Введите url',
      mainColor: 'Основной цвет',
      activeColor: 'Активный цвет',
      bgColor: 'Цвет фона',

      resetSetting: 'Сброс настроек проекта',
      confResetSetting: 'Вы уверены, что хотите поставить дефолтные настройки проекта?',

      labelFontSize: 'Размер текста',
      labelFontFamily: 'Шрифт текста',
      labelColorText: 'Цвет текста',
      labelFontHeadline: 'Шрифт заголовков',
      labelSizeHeadline: 'Размер заголовков',
    },
    process: {
      process: 'Процесс',
      processName: 'Название процесса',
      editProcess: 'Редактировать процесс',
      createProcess: 'Создать процесс',
      delete: 'Удалить',
      cancel: 'Отмена',
      save: 'Сохранить',
      create: 'Создать',
      requiredField: 'Обязательное поле',
      plProcessName: 'Введите название процесса',
      deleteProcess: 'Удаление процесса',
      confDeleteProcess: 'Вы уверены, что хотите удалить процесс?',
      processTheme: 'Тема процесса',
      light: 'Cветлая',
      dark: 'Темная',

      unAvailablePreset: 'Пресет недоступен',

      warnNoRules: 'Отсутствуют правила между:',
      errorCustomNode: 'На канвасе присутсвуют кастомные ноды:',
      errorStartNode: 'Количество начальных нод больше одной, проверьте связи',
    },
    modal: {
      cancel: 'Отмена',
      yes: 'Да',
      clickToSelectImg: 'Нажмите чтобы выбрать изображение',
      selectImg: 'Выбрать изображение',
      selectMediaFromList: 'Выберите изображение из представленного списка или загрузите новое',
      uploadNewImg: 'Загрузить новое изображение',
      delete: 'Удалить',
      save: 'Сохранить',
    },
    node: {
      performanceNode: 'Характеристики ноды',
      presets: 'Пресеты',
      preset: 'Пресет',
      screens: 'Экраны',
      screen: 'Экран',
      conditions: {
        title: 'Условные переходы',
        empty: 'Условия остутствуют',
        internal: {
          title: 'Внутренние',
          empty: 'Внутренние преходы остутствуют'
        },
        external: {
          title: 'Внешние',
          empty: 'Внешние преходы остутствуют'
        },
      },
      actions: {
          title: 'Действия',
          from: 'из',
          to: 'в',
          on: 'при ',
          do: ' делать ',
      },
      node: 'Нода',
      intoPreset: {
        addPreset: 'Добавить пресет',
        delAllRules: 'Удалить все правила у пресета',
        canvasName: 'Имя на канвасе',
        plNameField: 'Введите имя',
        selectPreset: 'Выберете пресет',
        selectScreen: 'Выберете экран',
        withoutPreset: 'Нода без пресетов',
        defaultPreset: 'Дефолтный пресет',
      },
      intoCondition: {
        selectCondition: 'Выберите условие',
        showRule: 'Раскрыть правило',
        delRule: 'Удалить правило',
        condition: 'Условие',
        service: 'Сервис',
        action: 'Действие',
        request: 'Запрос',
        answer: 'Ответ',
        confDelete: 'Подтверждение удаления',
        deleteRule: 'Вы уверены, что хотите удалить?',
        deleteCondition: 'Удалить условие',
        confDeleteRule: 'Вы уверены, что хотите удалить условие?',
        confDeleteTrans: 'Вы уверены, что хотите удалить переход?',
        // : '',
      }
    },
    intoWidget: {
      performance: 'Характеристики',
      performanceWidget: 'Характеристики виджета',
    },
    gradientColor: {
      color: 'Цвет',
      degree: 'Градус',
      setBgColor: 'Задать цвет фона',
      removeBgColor: 'Убрать цвет фона', //Remove background color
      mono: 'моно',
      gradient: 'градиент',
      bgColor: 'Цвет фона',
      // : '',
    },
    multilang: {
      error: 'Что-то пошло не так',
      load: 'Загрузка...',
      enterContent: 'Введите содержимое',
    },
    screenEditor: {
      text: 'Текст',
      icon: 'Иконка',
      label: 'Лейбл',
      countSymbol: 'Количество символов',
      countCells: 'Количество ячеек',
      mask: 'Маска',
      placeholder: 'Плейсхолдер',
      position: 'Расположение',
      image: 'Изображение',
      fontSize: 'Размер шрифта',
      underline: 'Подчеркивание',
      bgColor: 'Цвет фона',
      selectColor: 'Выбор цвета',
      align: {
        center: 'Центр',
        left: 'Лево',
        right: 'Право',
      }
    },
    pageBuilder: {
      displayName: 'Отображаемое имя',
      nodeType: 'Тип ноды',
      nodeName: 'Имя ноды - уникальное имя (camelCase)',
      options: 'Параметры',
      createModalName: 'Создать новую ноду',
      changeModalName: 'Изменить ноду',
    },
    variables: {
      variables: 'Переменные',
      fieldName: 'Название поля',
      personalData: 'Личные данные',
      localSave: 'Локальное сохранение',
      errorSearchSeveralParameters: 'Введите один параметр для поиска',
      fieldSettings: 'Настройки поля',
      actions: 'Действия',
      alert: {
        createSuccess: 'Переменная успешно создана',
        createError: 'Ошибка при создании переменной',
        updateSuccess: 'Переменная успешно обновлена',
        updateError: 'Ошибка при обновлении переменной',
        deleteSuccess: 'Переменная успешно удалена',
        deleteError: 'Ошибка при удалении переменной',
        errorFindVariable: 'Не удалось найти переменную'
      },
    },
    externalSource: {
      request: 'Запрос',
      response: 'Ответ',
      methodName: 'Название метода',
      methodUrl: 'Url метода',
      apiId: 'Апи',
      typeId: 'Тип',
      selectApiId: 'Выбрать апи',
      selectTypeId: 'Выбрать тип',
      isAsync: "Асинхронный",
      alert: {
        searchError: 'Неверный параметр поиска',
        createError: 'Ошибка при создании метода',
        createSuccess: 'Метод успешно создан',
        updateError: 'Ошибка при обновлении метода',
        updateSuccess: 'Метод успешно обновлен',
        deleteError: 'Ошибка при удалении метода',
        deleteSuccess: 'Метод успешно удален',
      }
    },
    rules: {
      ruleName: 'Название правила',
      method: 'Метод',
      fieldName: 'Название поля',
      resultApiField: 'Результат',
      operator: 'Оператор',
      isRequired: 'Требуется',
      answer: 'Ответ',
      selectFieldName: 'Выбрать поле',
      selectOperator: 'Выбрать оператор',
      selectMethod: 'Выбрать метод',
      variable: 'Переменная',
      alert: {
        searchError: 'Неверный параметр поиска',
        createError: 'Ошибка при создании правила',
        createSuccess: 'Правило успешно создано',
        updateError: 'Ошибка при обновлении правила',
        updateSuccess: 'Правило успешно обновлено',
        deleteError: 'Ошибка при удалении правила',
        deleteSuccess: 'Правило успешно удалено',
      }
    },
    references: {
      propertyId: 'Свойство',
      alert: {
        createError: 'Ошибка при создании справочника',
        createSuccess: 'Справочник успешно создан',
        updateError: 'Ошибка при обновлении справочника',
        updateSuccess: 'Справочник успешно обновлен',
        deleteError: 'Ошибка при удалении справочника',
        deleteSuccess: 'Справочник успешно удален',
      }
    },
    projectList: {
      instance: 'Экземпляр',
      processes: 'Процесс',
      properties: 'Характеристики',
      connectToProcess: 'Связать с процессом',
      alert: {
        createError: 'Ошибка при создании проекта',
        createSuccess: 'Проект успешно создан',
        updateError: 'Ошибка при обновлении проекта',
        updateSuccess: 'Проект успешно обновлен',
        deleteError: 'Ошибка при удалении проекта',
        deleteSuccess: 'Проект успешно удален',
        saveProcessError: 'Ошибка при связывании процесса',
        saveProcessSuccess: 'Процесс успешно привязан',
        deleteProcessError: 'Ошибка при отвязывании процесса',
        deleteProcessSuccess: 'Процесс успешно отвязан',
      }
    },
    endpoint: {
      instance: 'Инстанс',
      endpointUrl: 'Эндпоинт',
      function: 'Функция',
      logId: 'Лог',
      apiMethodId: 'Api метод',
      nodeFuncId: 'Нода функция',
      method: 'Метод',
      node: 'Нода',
      request: 'Запрос',
      response: 'Ответ',
      alert: {
        searchError: 'Неверный параметр поиска',
        createError: 'Ошибка при создании эндпоинта',
        createSuccess: 'Эндпоинт успешно создан',
        updateError: 'Ошибка при обновлении эндпоинта',
        updateSuccess: 'Эндпоинт успешно обновлен',
        deleteError: 'Ошибка при удалении эндпоинта',
        deleteSuccess: 'Эндпоинт успешно удален',
      }
    },
    property: {
      type: 'Тип',
      isGlobal: 'Глобально',
      isGroup: 'Группа',
      parentId: 'Родитель',
      tableReference: 'Ссылка',
      refValues: 'Исходные значения',
      defaultValue: 'Значение по умолчанию',
      alert: {
        searchError: 'Неверный параметр поиска',
        createError: 'Ошибка при создании свойства',
        createSuccess: 'Свойство успешно создано',
        updateError: 'Ошибка при обновлении свойства',
        updateSuccess: 'Свойство успешно обновлено',
        deleteError: 'Ошибка при удалении свойства',
        deleteSuccess: 'Свойство успешно удалено',
      }
    },
    actionCRUD: {
      actionName: 'Имя',
      actionAlias: 'Псевдоним',
      actionType: 'Тип',
      parameters: 'Параметры',
      alert: {
        searchError: 'Неверный параметр поиска',
        createError: 'Ошибка при создании действия',
        createErrorExistParams: 'Ошибка при создании действия, параметры уже существуют',
        createSuccess: 'Действие успешно создано',
        updateError: 'Ошибка при обновлении действия',
        updateSuccess: 'Действие успешно обновлено',
        deleteError: 'Ошибка при удалении действия',
        deleteSuccess: 'Действие успешно удалено',
      }
    },
  },


  en: {
    delete: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    create: 'Create',
    change: 'Change',
    close: 'Сlose',
    reset: 'Reset',
    download: 'Download',
    upload: 'Upload',
    update: 'Update',
    duplicate: 'Duplicate',
    edit: 'Edit',
    exit: 'Exit',
    go: 'Go',
    search: 'Search',
    description: 'Description',
    id: 'Id',
    name: 'Name',
    field: 'Field',
    order: 'Order',
    value: 'Value',
    langs: 'Langs',
    guides: 'Guides',
    preview: 'Preview',
    endpointUrl: 'Endpoint',
    helloScreen: {
      welcome: 'Welcome!',
      joinInAdmin: 'Join in admin-panel',
      welcomeBack: 'Welcome back',
      loginAndPass: 'Enter your login and password to log in',
      login: 'Login',
      instance: 'Instance',
      password: 'Password',
      signIn: 'Sign in',
    },
    menu: {
      processes: 'Processes', 
      widgets: 'Widgets',
      multiLang: 'Multi-language',
      settings: 'Settings',
      pageBuilder: 'Page Builder',
      templates: 'Templates',
      'content-configurator': 'Content Configurator',
      'variables-and-rules': 'Variables and rules',
      variables: 'Variables',
      rules: 'Rules',
      'external-sources': 'External sources',
      integration: "Integration",
      references: "References",
      'references-universal': "References universal",
      'projects-and-processes': 'Projects and processes',
      'projects-list': 'Projects list',
      endpoints: 'Endpoints',
      'page-builder': 'Editing a node',
      'properties': 'Properties',
      'properties-of-node': 'Properties of node',
      'actions': 'Actions',
      'actions-list': 'Actions list',
    },
    settings: {
      basic: 'Basic',
      images: 'Images',
      colors: 'Colors',
      projectName: 'Project name',
      plProject: 'Enter project name',
      companyName: 'Company name',
      plCompany: 'Enter company name',
      plSelectFile: 'Select file',
      logo: 'Logo',
      favicon: 'Favicon',
      preloaderColor: 'Preloader color',
      timeResendCode: 'Code retransmission time in seconds',
      plTimeResendCode: 'Enter time',
      globalUrl: 'Global url',
      plGlobalUrl: 'Enter url',
      imageUrl: 'Path to the folder with images',
      plImageUrl: 'Enter url',
      mainColor: 'Main color',
      activeColor: 'Active color',
      bgColor: 'Background color',

      resetSetting: 'Reset project settings',
      confResetSetting: 'Are you sure you want to set the default project settings?',

      labelFontSize: 'Font size',
      labelFontFamily: 'Font family',
      labelColorText: 'Color text',
      labelFontHeadline: 'Font headline',
      labelSizeHeadline: 'Size headline',
    },
    process: {
      process: 'Process',
      processName: 'Process name',
      editProcess: 'Edit process',
      createProcess: 'Create process',
      delete: 'Delete',
      cancel: 'Cancel',
      save: 'Save',
      create: 'Create',
      requiredField: 'Required field',
      plProcessName: 'Enter process name',
      deleteProcess: 'Delete process',
      confDeleteProcess: 'Are you sure you want to delete the process?',
      processTheme: 'Process theme',
      light: 'Light',
      dark: 'Dark',

      unAvailablePreset: 'Unavailable preset',

      warnNoRules: 'There are no rules between:',
      errorCustomNode: 'There are custom nodes on the canvas:',
      errorStartNode: 'The number of starting nodes is more than one, check the connections',
    },
    modal: {
      cancel: 'Cancel',
      yes: 'Yes',
      clickToSelectImg: 'Click to select image',
      selectImg: 'Select image',
      selectMediaFromList: 'Select an image from the list provided or upload a new one',
      uploadNewImg: 'Upload new image',
      delete: 'Delete',
      save: 'Save',
    },
    node: {
      performanceNode: 'Performance node',
      presets: 'Presets',
      node: 'Node',
      preset: 'Preset',
      screens: 'Screens',
      screen: 'Screen',
      conditions: {
        title: 'Transition conditions',
        empty: 'Conditions is empty',
        internal: {
          title: 'Internal',
          empty: 'Internal transition is empty'
        },
        external: {
          title: 'External',
          empty: 'External transition is empty'
        },
      },
      actions: {
        title: 'Actions',
        from: 'from',
        to: 'to',
        on: 'on ',
        do: ' do ',
      },
      intoPreset: {
        addPreset: 'Add preset',
        delAllRules: 'Delete all rules from a preset',
        canvasName: 'Name on canvas',
        plNameField: 'Enter name',
        selectPreset: 'Select preset',
        selectScreen: 'Select screen',
        withoutPreset: 'Node without presets',
        defaultPreset: 'Default preset',
      },
      intoCondition: {
        selectCondition: 'Select condition',
        showRule: 'Show rule',
        delRule: 'Delete rule',
        condition: 'Condition',
        service: 'Service',
        action: 'Action',
        request: 'Request',
        answer: 'Answer',
        confDelete: 'Deletion confirmation',
        deleteRule: 'Are you sure you want to delete?',
        deleteCondition: 'Delete condition',
        confDeleteRule: 'Are you sure you want to delete the condition?',
        confDeleteTrans: 'Are you sure you want to delete the transition?',
        // : '',
      }
    },
    intoWidget: {
      performance: 'Performance',
      performanceWidget: 'Widget performance',
    },
    gradientColor: {
      color: 'Color',
      degree: 'Degree',
      setBgColor: 'Set background color',
      removeBgColor: 'Remove background color',
      mono: 'mono',
      gradient: 'gradient',
      bgColor: 'Background color',
    },
    multilang: {
      error: 'Something went wrong',
      load: 'loading...',
      enterContent: 'Enter content',
    },
    screenEditor: {
      text: 'Text',
      icon: 'Icon',
      label: 'Label',
      countSymbol: 'Count symbol',
      countCells: 'Count cells',
      mask: 'Mask',
      placeholder: 'Placeholder',
      position: 'Position',
      image: 'Image',
      fontSize: 'Font size',
      underline: 'Underline',
      bgColor: 'background color',
      selectColor: 'Select color',
      align: {
        center: 'Center',
        left: 'Left',
        right: 'Right',
      }
    },
    pageBuilder: {
      displayName: 'Display name',
      nodeType: 'Type of node',
      nodeName: 'Node name - Unique name (camelCase)',
      options: 'Options',
      createModalName: 'Create new node',
      changeModalName: 'Change node',
    },
    variables: {
      variables: 'Variables',
      fieldName: 'Field name',
      personalData: 'Personal data',
      localSave: 'Local save',
      errorSearchSeveralParameters: 'Enter one search parameter',
      fieldSettings: 'Field settings',
      actions: 'Actions',
      alert: {
        createSuccess: 'Variable created successfully',
        createError: 'Error creating variable',
        updateSuccess: 'Variable updated successfully',
        updateError: 'Error updating variable',
        deleteSuccess: 'Variable deleted successfully',
        deleteError: 'Error when deleting variable',
        errorFindVariable: 'Could not find variable',
      }
    },
    externalSource: {
      request: 'Request',
      response: 'Response',
      methodName: 'Method name',
      methodUrl: 'Method url',
      apiId: 'Api',
      typeId: 'Type',
      selectApiId: 'Select api',
      selectTypeId: 'Select type',
      isAsync: "Async",
      alert: {
        searchError: 'Invalid search parameter',
        createError: 'Error creating method',
        createSuccess: 'Method created successfully',
        updateError: 'Error updating method',
        updateSuccess: 'Method updated successfully',
        deleteError: 'Error when deleting method',
        deleteSuccess: 'Method deleted successfully',
      },
    },
    rules: {
      ruleName: 'Rule name',
      method: 'Method',
      fieldName: 'Field name',
      resultApiField: 'Result',
      operator: 'Operator',
      isRequired: 'Required',
      answer: 'Answer',
      selectFieldName: 'Select field name',
      selectOperator: 'Select operator',
      selectMethod: 'Select method',
      variable: 'Variable',
      alert: {
        searchError: 'Invalid search parameter',
        createError: 'Error creating rule',
        createSuccess: 'Rule created successfully',
        updateError: 'Error updating rule',
        updateSuccess: 'Rule updated successfully',
        deleteError: 'Error when deleting rule',
        deleteSuccess: 'Rule deleted successfully',
      },
    },
    references: {
      propertyId: 'Property',
      alert: {
        createError: 'Error creating reference',
        createSuccess: 'Reference created successfully',
        updateError: 'Error updating reference',
        updateSuccess: 'Reference updated successfully',
        deleteError: 'Error when deleting reference',
        deleteSuccess: 'Reference deleted successfully',
      }
    },
    projectList: {
      instance: 'Instance',
      processes: 'Processes',
      properties: 'Properties',
      connectToProcess: 'Connect to process',
      alert: {
        createError: 'Error creating project',
        createSuccess: 'Project created successfully',
        updateError: 'Error updated project',
        updateSuccess: 'Project updated successfully',
        deleteError: 'Error when deleting project',
        deleteSuccess: 'Project deleted successfully',
        saveProcessError: 'Error when connecting process',
        saveProcessSuccess: 'Process connected',
        deleteProcessError: 'Error when unconnecting process',
        deleteProcessSuccess: 'Process unconnected',
      }
    },
    endpoint: {
      instance: 'Instance',
      endpointUrl: 'Endpoint',
      function: 'Function',
      logId: 'Log',
      apiMethodId: 'Api method',
      nodeFuncId: 'Node function',
      method: 'Method',
      node: 'Node',
      request: 'Request',
      response: 'Response',
      alert: {
        searchError: 'Invalid search parameter',
        createError: 'Error creating endpoint',
        createSuccess: 'Endpoint created successfully',
        updateError: 'Error updating endpoint',
        updateSuccess: 'Endpoint updated successfully',
        deleteError: 'Error when deleting endpoint',
        deleteSuccess: 'Endpoint deleted successfully',
      },
    },
    property: {
      type: 'Type',
      isGlobal: 'Global',
      isGroup: 'Group',
      parentId: 'Parent',
      tableReference: 'Reference',
      refValues: 'Ref values',
      defaultValue: 'Default value',
      alert: {
        searchError: 'Invalid search parameter',
        createError: 'Error creating property',
        createSuccess: 'Property created successfully',
        updateError: 'Error updating property',
        updateSuccess: 'Property updated successfully',
        deleteError: 'Error when deleting property',
        deleteSuccess: 'Property deleted successfully',
      },
    },
    actionCRUD: {
      actionName: 'Name',
      actionAlias: 'Alias',
      actionType: 'Type',
      parameters: 'Parameters',
      alert: {
        searchError: 'Invalid search parameter',
        createError: 'Error creating action',
        createErrorExistParams: 'Error creating action, params is exist',
        createSuccess: 'Action created successfully',
        updateError: 'Error updated action',
        updateSuccess: 'Action updated successfully',
        deleteError: 'Error when deleting action',
        deleteSuccess: 'Action deleted successfully',
      }
    },
  }
};

const i18n = new VueI18n({
  locale: localStorage.systemLang ?? DEFAULT_LANGUAGE,
  messages
});

export default i18n